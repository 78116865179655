













import { Component, Prop, Vue } from 'vue-property-decorator';
import BackButton from '@/components/BackButton.vue';
import ViewTitle from '@/components/ViewTitle.vue';
import DisclaimerTerms from '@/components/DisclaimerTerms.vue';

export default Vue.extend({
    components: {
        BackButton,
        ViewTitle,
        DisclaimerTerms,
    },
});

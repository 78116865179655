




import { Component, Prop, Vue } from 'vue-property-decorator';

export default Vue.extend({
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
});
